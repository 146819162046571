import styled from 'styled-components'

const Container = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  border: 0.1rem solid currentColor;
  border-radius: 100rem;
  padding: 1.9rem 3rem;
  min-width: 14rem;
  line-height: 1.2;
  transition: background 120ms, border-color 120ms;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: var(--color-secondary-2);
    border-color: var(--color-secondary-2);
  }

  p + & {
    margin-top: 2rem;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media (min-width: 62.5em) {
    min-width: 20rem;
    padding: 3rem 4rem;
  }
`

const Button = props => <Container {...props} />

export default Button
