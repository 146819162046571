import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContentWrapper from '../components/content-wrapper'
import SectionTop from '../components/section-top'
import Button from '../components/button'

const PageIntro = styled(ContentWrapper)`
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (min-width: 48em) {
    margin-top: 20rem;
    margin-bottom: 12rem;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="Beklagar, vi kan inte hitta denna sida" />

    <PageIntro>
      <SectionTop title="Beklagar, vi kan inte hitta denna sida" as="h1">
        <Button as={Link} to="/">
          Gå till startsidan
        </Button>
      </SectionTop>
    </PageIntro>
  </Layout>
)

export default NotFoundPage
